<template>
  <div class="meunBox">
    <div class="title" v-if="type === 1 && lessonStatus">选择商品</div>
    <section v-if="type === 1">
      <!-- 卡片选择 -->
      <div
        class="goodsSelBox"
        v-show="
          lessonStatus && item.sellStatus !== 0 && mealGroupData.length > 0
        "
        v-for="(item, index) in mealGroupData"
        :key="index"
      >
        <div class="do">
          <img
            v-show="item.closeStatus"
            src="@/assets/image/close2.png"
            alt=""
            class="doIcon"
            @click="changeGroup(index, item.closeStatus)"
          />
          <img
            v-show="!item.closeStatus"
            src="@/assets/image/open.png"
            alt=""
            class="doIcon"
            @click="changeGroup(index, item.closeStatus)"
          />
          <span class="goodsTitle">{{ item.name }}</span>
          <span class="intro" v-if="item.sellStatus"
            >共{{ item.goods.length }}门，任选{{ item.sellCount }}门
          </span>
          <span class="intro" v-else
            >共{{ item.goods.length }}门，任选{{ item.goods.length }}门
          </span>
          <span class="stand"></span>
          <span class="selNum">
            已选
            <span v-if="item.sellStatus">
              <span class="nowSel">{{ item.selected_count || 0 }}</span> /
              <span class="canSel">{{ item.sellCount }}</span>
            </span>
            <span v-else>
              <span class="nowSel">{{ item.goods.length }}</span> /
              <span class="canSel">{{ item.goods.length }}</span>
            </span>
          </span>
        </div>
        <div class="classBox" v-show="item.closeStatus">
          <ul>
            <li
              v-for="(item2, index2) in item.goods"
              :key="index2"
              :class="{ seled: item2.checked }"
              @click="changeSel(index, index2)"
            >
              <img
                src="@/assets/image/circular.png"
                v-show="!item2.checked"
                alt=""
                class="circularIcon"
              />
              <img
                src="@/assets/image/seled.png"
                v-show="item2.checked"
                alt=""
                class="circularIcon"
              />
              <div :class="{ selComplete: item.selComplete && !item2.checked }">
                {{ item2.name }}
              </div>
              <!-- <div :class="{selComplete:item.selComplete && !item2.checked}">{{item2.name}},{{item.selComplete}},{{item2.checked}}</div> -->
            </li>
          </ul>
        </div>
      </div>

      <!-- 选完课程后的表格 -->
      <div
        class="el-table el-table--border"
        v-show="!lessonStatus && mealGroupData.length > 0"
      >
        <!-- 表头 -->
        <div class="el-table__header-wrapper">
          <table class="el-table__header" border="1">
            <thead>
              <tr>
                <th class="classGroup normal-table-header">
                  <div class="cell">班型分组</div>
                </th>
                <th class="classType normal-table-header">
                  <div class="cell">班型</div>
                </th>
                <th class="is-leaf normal-table-header">
                  <div class="cell">课程</div>
                </th>
                <th class="classTyle-herder normal-table-header">
                  <div class="cell">类型</div>
                </th>
                <th class="classTyle-herder normal-table-header">
                  <div class="cell">上课形式</div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="el-table__body-wrapper course-tbody">
          <table class="el-table__body" border="1">
            <!-- classType班型选择 -->
            <tbody>
              <tr
                v-for="(classGroup, classGroupIndex) in mealGroupData"
                :key="classGroupIndex"
              >
                <!-- 班型分组列 -->
                <td class="classGroup no-padding">
                  <div class="cell">{{ classGroup.name }}</div>
                </td>
                <td class="no-padding no-right-border">
                  <div class="el-table__body-wrapper">
                    <table class="el-table__body">
                      <tbody>
                        <tr
                          v-for="(
                            classType, classTypeIndex
                          ) in classGroup.goods"
                          :key="classTypeIndex"
                          v-show="classType.checked"
                        >
                          <!-- 班型列 -->
                          <td class="classType no-padding">
                            <div class="cell">{{ classType.name }}</div>
                          </td>
                          <!-- 课程列 -->
                          <td class="no-padding no-right-border">
                            <table class="el-table__body">
                              <tbody>
                                <tr
                                  v-for="(
                                    courseInfo, courseInfoIndex
                                  ) in classType.courseInfoList"
                                  :key="courseInfoIndex"
                                >
                                  <td>
                                    <div class="cell">
                                      {{ courseInfo.courseName }}
                                    </div>
                                  </td>
                                  <td class="classTyle no-right-border">
                                    <div class="cell">
                                      {{ courseInfo.goodsTypeName }}
                                    </div>
                                  </td>
                                  <td class="classTyle no-right-border">
                                    <div class="cell">
                                      {{ courseInfo.classForm }}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="btns" v-show="allMustSel">
        <el-button
          size="large"
          type="primary"
          v-if="lessonStatus"
          v-show="mealGroupData.length > 0"
          @click="editLesson('save')"
          >保存</el-button
        >
        <el-button
          size="large"
          type="primary"
          icon="el-icon-edit"
          v-else
          v-show="mealGroupData.length > 0"
          @click="editLesson('edit')"
          >修改已选课程</el-button
        >
      </div>
    </section>

    <el-table
      v-else
      border
      stripe
      size="mini"
      element-loading-text="拼命加载中"
      :header-cell-style="{ background: '#e5f2ff' }"
      highlight-current-row
      style="width: 100%"
      header-cell-class-name="normal-table-header"
      :data="oneItem"
    >
      <el-table-column :show-overflow-tooltip="true" label="课程" prop="courseName" />
      <el-table-column :show-overflow-tooltip="true" label="类型" prop="goodsTypeName" />
      <el-table-column :show-overflow-tooltip="true" label="上课形式" prop="classForm" />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lessonStatus: false,
    };
  },
  props: {
    mealGroupData: {
      type: [Array, Object],
      required: true,
    },
    type: Number,
    oneItem: Array, //单品的数据
  },
  methods: {
    getSeledClass() {
      let tmpArr = this.mealGroupData;
      for (let i of tmpArr) {
        //sellStatus === 1表示任选
        if (i.sellStatus === 1) {
          //检查是否选满了规格
          let selLength = i.goods.filter((item) => item.checked === true);
          if (selLength.length < i.sellCount) {
            alert("选择不满足条件");
            return false;
          }
          console.log("选择的长度", selLength.length);
        }
      }
      return true;
    },
    editLesson(type) {
      if (type === "save" && !this.getSeledClass()) {
        return;
      }
      this.lessonStatus = type === "save" ? false : true;
      this.$emit('confirmClass', this.lessonStatus)
    },
    changeGroup(index, closeStatus) {
      this.mealGroupData[index].closeStatus = !closeStatus;
    },
    changeSel(index, index2) {
      if (
        this.mealGroupData[index].sellStatus == 0 ||
        (this.mealGroupData[index].selected_count >=
          this.mealGroupData[index].sellCount &&
          !this.mealGroupData[index].goods[index2].checked)
      ) {
        console.log(
          "分组数量",
          this.mealGroupData[index].sellStatus,
          this.mealGroupData[index].goods.length
        );
        let tipNum =
          this.mealGroupData[index].sellStatus == 0
            ? this.mealGroupData[index].goods.length
            : this.mealGroupData[index].sellCount;
        return this.$message.warning(`该分组只能选择${tipNum}个课程`);
      }
      this.mealGroupData[index].goods[index2].checked =
        !this.mealGroupData[index].goods[index2].checked;
      this.mealGroupData[index].selected_count = 0;
      for (let item of this.mealGroupData[index].goods) {
        if (item.checked) {
          this.mealGroupData[index].selected_count++;
        }
      }
      console.log(
        "对比参数",
        this.mealGroupData[index].selected_count,
        this.mealGroupData[index].sellCount
      );
      if (
        this.mealGroupData[index].selected_count >=
        this.mealGroupData[index].sellCount
      ) {
        this.mealGroupData[index].selComplete = true;
      } else {
        this.mealGroupData[index].selComplete = false;
      }
    },
    findSeled() {
      let seled = [];
      for (let i in this.mealGroupData) {
        for (let j in this.mealGroupData[i].goods) {
          if (this.mealGroupData[i].goods[j].checked) {
            seled.push(this.mealGroupData[i].goods[j]);
          }
        }
      }
      return seled;
    },
  },
  watch: {
    mealGroupData() {
      for (let i of this.mealGroupData) {
        console.log(i);
        if (i.sellStatus != 0) {
          this.lessonStatus = true;
          return;
        }
      }
      console.log("mealGroupData", this.mealGroupData);
      this.lessonStatus = false; //如果全都为必选，则直接展示表格
    },
  },
  computed: {
    allMustSel() {
      for (let i of this.mealGroupData) {
        if (i.sellStatus != 0) {
          return true;
        }
      }
      return false; //如果全都为必选，则直接展示表格
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  .el-table__header,
  .el-table__body {
    width: 100%;
  }
  .course-tbody {
    // height: 380px;
    max-height: 380px;
    overflow-y: auto;
  }
  .classGroup {
    width: 160px;
  }
  .classType {
    width: 540px;
  }
  .classTyle {
    width: 125px;
  }
  .classTyle-herder {
    width: 125px;
  }
  .no-padding {
    padding: 0;
  }
  .no-right-border {
    border-right: 0;
  }
}
.meunBox {
  min-width: 900px;
  .title {
    height: 54px;
    background: #e5f2ff;
    line-height: 54px;
    padding: 0 30px;
  }
  .goodsSelBox {
    .classBox {
      ul {
        background: #e1e4eb;
        max-height: 354px;
        padding: 20px 0 20px 20px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        box-sizing: border-box;
        .seled {
          border: 1px solid #337AFF;
        }
        li {
          width: 276px;
          height: 94px;
          background: #fff;
          margin-bottom: 20px;
          margin-right: 20px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          border-radius: 6px;
          .circularIcon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          .selComplete {
            color: #adadad;
          }
        }
      }
    }
    .do {
      width: 100%;
      height: 60px;
      line-height: 60px;
      padding: 0 20px 0 30px;
      display: flex;
      align-items: center;
      .doIcon {
        width: 16px;
        height: 16px;
      }
      .goodsTitle {
        margin: 0 12px 0 10px;
      }
      .intro {
        background: #f3f6fb;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        color: #666666;
        font-size: 12px;
      }
      .stand {
        flex: 1;
      }
      .selNum {
        .nowSel {
          color: #337AFF;
        }
      }
    }
  }
}
.sub-title {
  margin-bottom: 18px;
  font-size: 16px !important;
  font-weight: 700;
  color: #666;
}
.btns {
  margin-top: 32px;
}

.el-table {
  th {
    background: #e5f2ff;
    padding: 10px 0;
  }
  td {
    padding: 8px;
  }
}

</style> 